import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Context from 'components/common/Context'
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { D } from '@dayetopia/library';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconQuestion from '../../images/icon-question-mark.svg';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const StyledRating = withStyles({
    root: {
        '@media (max-width:768px)': {
            marginBottom: '0.5em',
            marginTop: '0.1em',
        }
    },
    icon: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    iconFilled: {
        color: D.gold,
    },
    iconHover: {
        color: D.gold
    },
})(Rating);

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon fontSize="large" />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon fontSize="large" />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon fontSize="large" />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon fontSize="large" />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};
const StyledIcon = withStyles({
    root: {
        color: D.gold,
        marginLeft: '8px'
    }
})(HelpIcon)

const StyledGrid = withStyles({
    root: {
        display: "flex",
        marginBottom: '40px',
        alignItems: 'center',
        paddingRight: '6em !important',
        paddingLeft: '6em !important',
        '@media (max-width:1200px)': {
            marginBottom: '0px',
            marginTop: '10px',
            justifyContent: 'center'
        },

    }
})(Grid)


export default ({ id, info, question }) => {
    const { questions, dispatchQuestions } = useContext(Context)
    const [showToolTip, setShowToolTip] = useState(false);

    return (
        <>
            {console.log('questions', questions)}
            <Grid container layout={'row'}>
                <StyledGrid item xs={12} md={6}>
                    <H3Styled>
                        {question}
                    </H3Styled>
                    <TooltipWrapper>
                        <QuestionMark
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        />
                        {showToolTip &&
                            <ToolTip>
                                <PStyled mods={[14]}>{info}</PStyled>
                            </ToolTip>}
                    </TooltipWrapper>
                </StyledGrid>
                <Grid item xs={12} md={6}>
                    <StyledRating
                        name={id}
                        // value={questions[id]}
                        size="large"
                        onChange={(e, rating) => {
                            dispatchQuestions({ type: 'SUBMIT_ANSWER', payload: { id, question, rating } })
                        }}
                        IconContainerComponent={IconContainer}
                    />
                </Grid>
            </Grid>
        </>
    )
}


const H3Styled = styled(D.H3)`
  font-weight: 600;
  font-size: 2.4em;
  line-height: 1.4;
  margin-bottom: 0px;
  text-align: left;
  margin-right: 2em;
  @media only screen and (max-width: 768px) {
    font-size: 1.8em;
    line-height: 1.8;
    text-align: center;
    margin-right: 1em;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
`;
const ToolTip = styled.div`
  margin-top: 60px;
  width: 335px;
  border: solid 1px #e5ad44;
  background-color: #faebcb;
  position: absolute;
  z-index: 2;
  padding: 20px;
  animation: fadein 0.2s;
  right: -26px;
  top: -22px;
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid#e5ad44;
    right: 27px;
    top: -20px;
  }
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #faebcb;
    right: 27px;
    top: -19px;
  };
  @media only screen and (max-width: 768px) {
   width: 200px;
  }
  
`;

const QuestionMark = styled(IconQuestion)`
  cursor: pointer;
`;

const PStyled = styled(D.P)`
  margin-bottom: 8px;
`;