import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Context from 'components/common/Context'
import styled from 'styled-components';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { D } from '@dayetopia/library';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconQuestion from '../../images/icon-question-mark.svg';

const StyledRadio = withStyles({
    root: {
        color: D.gold,
        '&$checked': {
            color: D.gold,
        },
    },
    checked: {}
})(Radio)
const StyledFormControl = withStyles({
    root: {
        display: 'block'
    }
})(FormControl)

const StyledFormLabel = withStyles({
    root: {
        textAlign: 'center'
    }
})(FormLabel)
export default ({ data }) => {
    const [showToolTip, setShowToolTip] = useState(false);
    const { preferences, dispatchPreferences } = useContext(Context)
    const { question, id, info, options } = data
    const handleChange = (e) => {
        let answer = e.target.value;
        dispatchPreferences({ type: 'SUBMIT_ANSWER', payload: { id, question, answer } })
    };
    return (
        <>
            <BorderWrapper >
                <StyledFormControl component="fieldset">
                    <StyledFormLabel component="legend">
                        <LabelWrapper>
                            <H3Styled>
                                {question}
                            </H3Styled>
                            <TooltipWrapper>
                                <QuestionMark
                                    onMouseEnter={() => setShowToolTip(true)}
                                    onMouseLeave={() => setShowToolTip(false)}
                                />
                                {showToolTip &&
                                    <ToolTip>
                                        <PStyled mods={[14]}>{info}</PStyled>
                                    </ToolTip>}
                            </TooltipWrapper>
                        </LabelWrapper>
                    </StyledFormLabel>
                    <RadioGroup aria-label="gender" name={id} value={preferences.id} onChange={handleChange}>
                        {options.map(option => (
                            <FormControlLabel value={option.value} control={<StyledRadio color="default" checked={preferences[id] === option.value} />} label={option.label} />
                        ))}
                    </RadioGroup>
                </StyledFormControl>
            </BorderWrapper>
        </>
    )
}

const H3Styled = styled(D.H3)`
  font-weight: 600;
  font-size: 1.3em;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 1em;
  @media only screen and (max-width: 768px) {
    font-size: 1.4em;
    line-height: 1.2;
  }
`;

const BorderWrapper = styled.div`
border: 2px solid ${D.darkGreen};
padding: 20px;
margin-bottom: 20px;
justify-content:center;
flex-direction: column;
height:100%;
background-color: white;
`

const LabelWrapper = styled.div`
display: flex;
`

const TooltipWrapper = styled.div`
  position: relative;
`;
const ToolTip = styled.div`
  margin-top: 60px;
  width: 335px;
  border: solid 1px #e5ad44;
  background-color: #faebcb;
  position: absolute;
  z-index: 2;
  padding: 20px;
  animation: fadein 0.2s;
  right: -26px;
  top: -22px;
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid#e5ad44;
    right: 27px;
    top: -20px;
  }
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #faebcb;
    right: 27px;
    top: -19px;
  };
  @media only screen and (max-width: 768px) {
   width: 200px;
  }
  
`;

const QuestionMark = styled(IconQuestion)`
  cursor: pointer;
`;

const PStyled = styled(D.P)`
  margin-bottom: 8px;
  font-size:16px;
`;