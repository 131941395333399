import React from 'react'
import { Router } from '@reach/router'
import Provider from 'providers/Provider'
import AppWrapper from 'components/AppWrapper'
import Survey from 'components/Survey'
import Applicator from 'components/Applicator'
import Complete from 'components/Complete'
import NotFound from 'components/common/NotFound'
import Login from 'components/Login'

export default () => (
  <AppWrapper>
    {console.log('here in index')}
    <Router>
      {/* <App path="/private/" component={App} /> */}
      <Survey path="/private/survey/" component={Survey} />
      <Applicator path="/private/applicator/" component={Applicator} />
      <Login path="/private/" component={Login} />
      <Complete path="/private/complete" component={Complete} />
      <NotFound default component={NotFound} />
    </Router>
  </AppWrapper>
)
