import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import setAuthToken from 'helpers/setAuthToken'
import Layout from 'components/common/Layout'
import Context from './common/Context'

export default ({ children }) => {
  const { user, dispatchUserAction } = useContext(Context)
  const [loading, setLoading] = useState(true)

  const fetchUser = async () => {
    if (
      window.location.pathname === '/private/survey' ||
      window.location.pathname === '/private/survey/' ||
      window.location.pathname === '/private/applicator' ||
      window.location.pathname === '/private/applicator/' ||
      window.location.pathname === '/private/complete' ||
      window.location.pathname === '/private/complete/'
    ) {
      navigate('/')
    }
    setLoading(false)
  }


  useEffect(() => {
    console.log('user', user)
    if (!user.isLoggedIn) {
      fetchUser()
    }
    setLoading(false)
  }, [])

  return (
    !loading && (
    <Layout isLoggedIn={user.isLoggedIn} >
      {children}
    </Layout>)
  )
}
