import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components';
import { TextInput } from './common/TextInput';
import Button from './common/Button';
import { navigate } from 'gatsby';
import { D } from '@dayetopia/library';
import { Link } from 'gatsby'
import Context from 'components/common/Context'
import SEO from 'components/common/Seo'
import ContentLoading from './common/TasksLoading'

export default () => {
  return (
    <>
      <SEO title="Survey" />
      <div className="container">
        <H1Styled>Thank you for making the applicator better!</H1Styled>
      </div>
    </>
  )
}

const H1Styled = styled(D.H1)`
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
  padding-top: 20px;

  @media only screen and (max-width: 500px) {
    margin-bottom: -40px;
    margin-left: 20px;
    margin-top: 100px;
  }
`;
const PStyled = styled(D.P1)`
  font-size: 1.6em;
  line-height: 1.6;
  margin-bottom: 20px;

`;




const ButtonStyled = styled(Button)`
  margin-bottom: 20px;
  text-align:right;
`;

const TextInputStyled = styled(TextInput)`
  margin-bottom: 20px;
`;

const ErrorText = styled(D.P)`
color: #ea534f;
font-size: 18px;
text-align: left;
`
