import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components';
import { TextInput } from './common/TextInput';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from './common/Button';
import { navigate } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import { D } from '@dayetopia/library';
import { Link } from 'gatsby'
import getAPIUrl from '../../getAPIUrl'
import Context from 'components/common/Context'
import SEO from 'components/common/Seo'
import ContentLoading from './common/TasksLoading'
import QuestionBox from './common/QuestionBox'
import * as Applicators from '../images/applicator'

const survey = [{
  question: "Type of Applicator",
  id: "type",
  info: "There are three types of applicator currently available - short, medium, long. Do you have a preference? Do you even care?",
  options: [
    {
      value: "Standard",
      label: "Standard"
    },
    {
      value: "Compact",
      label: "Compact"
    },
    {
      value: "Telescopic",
      label: "Telescopic"
    },
    {
      value: "Default",
      label: "As long as it works"
    }
  ],
},
{
  question: "Outer Grip",
  id: "outergrip",
  info: " What type of grip do you want at the area of the Tampon you hold when inserting?",
  options: [
    {
      value: "nogrip",
      label: "No Grip"
    },
    {
      value: "ridge",
      label: "Ridge"
    },
    {
      value: "groove",
      label: "Groove"
    },
    {
      value: "Default",
      label: "As long as it works"
    }
  ],
},
{
  question: "Material",
  id: "material",
  info: "What material type would your ideal applicator have? Do you even care?",
  options: [
    {
      value: "recyclable",
      label: "Recyclable"
    },
    {
      value: "compostable",
      label: "Compostable"
    },
    {
      value: "Default",
      label: "As long as it works"
    }
  ],
},
{
  question: "Colour",
  id: "colour",
  info: "Do you want this new applicator to have a fresh new colour?",
  options: [
    {
      value: "Clear",
      label: "Clear"
    },
    {
      value: "Orange",
      label: "Orange"
    },
    {
      value: "Cream",
      label: "Cream"
    },
  ],
},
{
  question: "Inner Grip",
  id: "innergrip",
  info: "How do you want part of the applicator you push to eject the tampon to look like? Does this even matter?",
  options: [
    {
      value: "small",
      label: "Small"
    },
    {
      value: "medium",
      label: "Medium"
    },
    {
      value: "large",
      label: "Large"
    },
    {
      value: "Default",
      label: "As long as it works"
    }
  ],
}]
export default () => {
  const [loading, setLoading] = useState(true)
  const { user, dispatchUserAction } = useContext(Context)
  const { preferences, dispatchPreferences } = useContext(Context)
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const word1 = 'gatsby'
  const word2 = 'astronaut'
  const word3 = 'icon'
  const image = word1 + '_' + word2

  useEffect(() => {
    setLoading(false)
  }, [])

  const StyledGrid = withStyles({
    root: {
        justifyContent: "center",
        '@media (max-width:1200px)': {
        },

    }
})(Grid)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const url = getAPIUrl(window.location.hostname)
    let preferences = {
      "preference 1": "pref value 1",
      "preference 2": "pref value 2"
    }
    try {
      // if (!email) {
      //   setError('Field is required')
      // } else {
      console.log('user', user)
      setError('')
      const response = await axios.post(`${url}/survey/preferences`, {
        userid: user.userid,
        preferences: preferences
      })
      console.log('data', response.data)
      response.data.updated && navigate('/private/complete/')
      // dispatchUserAction({ type: 'VERIFY_USER', payload: response.data.id })
      // console.log('1 user', user)
      // window.localStorage.setItem('userID', response.data.id)
      // console.log('2')
      // navigate('/private/survey/', {
      //   replace: true
      // })
      //}
    } catch (err) {
      console.log('error', err.response)
      err.response && setError(err.response.data.error)

    }
    setSubmitting(false)
  }

  return (
    <>
      <SEO title="Survey" />
      <div className="container">
        {loading ? (
          <ContentLoading />
        ) : (
            <>
              <H2Styled>Design Your Own Applicator</H2Styled>
              <PStyled>
                To help us at Daye develop a new improved Applicator (world’s best applicator), we would like to understand what your ideal applicator would look like. To help we have created an interactive survey below, where you can select from a few different options to design your own applicator. The options are not exhaustive and are really to inform us at Daye of the direction of our new applicator design.
              </PStyled>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid container layout={'row'} spacing={4}>
                    <Grid item xs={12} md={4}>
                      <QuestionBox data={survey[0]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <QuestionBox data={survey[1]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <QuestionBox data={survey[2]} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                
                    <MainImage
                      src={Applicators[`${preferences.type}_Normal_${preferences.colour}`]} />
                     
                  </Grid>
                  <StyledGrid container layout={'row'} spacing={4}>
                    <Grid item xs={12} md={4}>
                      <QuestionBox data={survey[3]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <QuestionBox data={survey[4]} />
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                      <QuestionBox data={survey[5]} />
                    </Grid> */}
                  </StyledGrid>
                </Grid>
                {/* <img src='./gatsby-icon.png' alt="image" />
                {
                  survey.map((question) => (
                    <QuestionBox data={question} />
                  )
                  )
                } */}

                <div className="center-text">
                  <ButtonStyled
                    type="submit"
                    disabled={isSubmitting}>
                    submit my design
                </ButtonStyled>
                  {/* <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-rounded gradient-green"
              >
                submit
              </button> */}

                </div>
              </form>
            </>
          )}
      </div>
    </>
  )
}

const PStyled = styled(D.P1)`
  font-size: 1.6em;
  line-height: 1.6;
  margin-bottom: 40px;
  max-width:800px;
  margin-left: auto;
  margin-right: auto;
`;

const H2Styled = styled(D.H2)`
  font-weight: 500;
  font-size: 3.2em;
  line-height: 1.25;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 2.4em;
    line-height: 1;
  }
`;
const ButtonStyled = styled(Button)`
  margin-top: 40px;
  margin-bottom: 10px;
  text-align:right;
`;

const TextInputStyled = styled(TextInput)`
  margin-bottom: 20px;
`;

const ErrorText = styled(D.P)`
color: #ea534f;
font-size: 18px;
text-align: left;
`
const BorderWrapper = styled.div`
border: 2px solid ${D.darkGreen};
padding: 20px;
margin-bottom: 20px;
justify-content:center;
flex-direction: column;
background-color: white;
width: '100%';
`
const MainImage = styled.img`
  max-height:350px;
  filter: drop-shadow(4px 4px 4px #6b6b6b47);
`;

// paleGreen,
//   paleBlue,
//   cream,
//   darkGreen,
//   palePink,
//   creamPink,
//   thirdPink,
//   gold,
//   paleGold,
//   midGreen,
//   creamGold,