import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios'
import styled from 'styled-components';
import { TextInput } from './common/TextInput';
import Button from './common/Button';
import { navigate } from 'gatsby';
import { D } from '@dayetopia/library';
import { Link } from 'gatsby'
import Context from 'components/common/Context'
import SEO from 'components/common/Seo'
import ContentLoading from './common/TasksLoading'
import QuestionRow from './common/QuestionRow'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import getAPIUrl from '../../getAPIUrl'
import TamponImage1 from '../images/tampon1.jpg'
import TamponImage2 from '../images/tampon2.jpg'
import TamponImage3 from '../images/tampon3.jpg'
import Grid from '@material-ui/core/Grid';

const survey = [{
  question: "Appearance",
  id: "appearance",
  info: "What do you think of the look of Daye’s applicator? "
},
{
  question: "Size",
  id: "size",
  info: "How do you feel about the size of Daye’s current applicator?"
},
{
  question: "Texture",
  id: "texture",
  info: "Do you like the way the applicator feels?"
},
{
  question: "Grip",
  id: "grip",
  info: "Do you find it easy or difficult to hold onto the applicator while inserting and removing the tampon?"
}, {
  question: "Insertion",
  id: "inserting",
  info: "How comfortable and fuss-free is it to insert a Daye tampon?"
},
{
  question: "Disposal",
  id: "disposing",
  info: "When you throw away the applicator, is it clear how you’re meant to dispose of it? Do you find the disposal too high-maintenance?"
},
{
  question: "Sustainability",
  id: "sustainability",
  info: "Do you think Daye’s applicator is sustainable enough?"
}]

const StyledCell = withStyles({
  root: {
    borderBottom: "none"
  }

})(TableCell)

export default () => {
  const [loading, setLoading] = useState(true)
  const { user, dispatchUserAction, questions } = useContext(Context)
  const [isSubmitting, setSubmitting] = useState(false)
  const [comment, setComment] = useState("")
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')


  useEffect(() => {
    setLoading(false)
  }, [])



  const handleSubmit = async e => {
    
    e.preventDefault()
    setSubmitting(true)
    const url = getAPIUrl(window.location.hostname)
    let answers = {
      ...questions,
      comment: comment
    }
    console.log("answers", answers)
    try {
      // if (!email) {
      //   setError('Field is required')
      // } else {
      console.log('user', user)
      setError('')
      const response = await axios.post(`${url}/survey/answers`, {
        userid: user.userid,
        answers: answers
      })
      console.log('data', response.data)
      response.data.updated && navigate('/private/applicator/')
      // dispatchUserAction({ type: 'VERIFY_USER', payload: response.data.id })
      // console.log('1 user', user)
      // window.localStorage.setItem('userID', response.data.id)
      // console.log('2')
      // navigate('/private/survey/', {
      //   replace: true
      // })
      //}
    } catch (err) {
      console.log('error', err.response)
      err.response && setError(err.response.data.error)

    }
    setSubmitting(false)
  }

  return (
    <>
      <SEO title="Survey" />
      <div className="container">
        {loading ? (
          <ContentLoading />
        ) : (
            <>
              <PStyled>
                Hi {user.first_name},
              </PStyled>
              <PStyled>
                Thank you for taking the time to share your feedback. At Daye we’re trying to develop the world’s best tampon applicator (a worthy pursuit, we know), so we want to hear from YOU—no pressure.
              </PStyled>
              <PStyled>
                We don’t want to get your hopes up so for the sake of transparency, we can’t guarantee all your suggestions will be implemented, but please don’t let that stop you. Don’t be shy!
              </PStyled>
              <form onSubmit={handleSubmit}>
                <BorderWrapper>
                  <H2Styled>Current Daye Tampon Applicator Feedback</H2Styled>
                  <ImageContainer>
                    <StyledImage src={TamponImage1} />
                    <StyledImage src={TamponImage2} />
                    <StyledImage src={TamponImage3} />
                  </ImageContainer>
                  <FormWrapper>
                    <Grid container >
                      <Grid container layout={'row'} spacing={4}>
                        <Grid item xs={12} md={6}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <PInnerStyled>Please rate the following features of Daye’s original applicator</PInnerStyled>
                          <PInnerStyled>(1 - Poor, 2 - Fair, 3 - Good, 4 - Very good, 5- Excellent)</PInnerStyled>
                        </Grid>
                      </Grid>
                      {survey.map((row) => (
                        <QuestionRow id={row.id} info={row.info} question={row.question} />
                      ))}
                    </Grid>
                  </FormWrapper>
                  <CommentWrapper>
                  <H3Styled>
                  If you want to share more about how you feel about the applicator, feel free to write it below in the comments box:
                  </H3Styled>
                  <Input
                    placeholder={'Share your thoughts'}
                    type="text"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                </CommentWrapper>
                </BorderWrapper>
               
                <div className="center-text">
                  <ButtonStyled
                    type="submit"
                    disabled={isSubmitting}>
                    submit
                </ButtonStyled>
                </div>
              </form>
            </>
          )}
      </div>
    </>
  )
}

const H2Styled = styled(D.H2)`
  font-weight: 500;
  font-size: 3.2em;
  line-height: 1.25;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 2.4em;
    line-height: 1;
  }
`;

// const PStyled = styled(D.P1)`
//   font-size: 2em;
//   line-height: 1.6;
//   margin-bottom: 10px;
//   max-width:800px;
//   margin-left: auto;
//   margin-right: auto;
// `;
const PStyled = styled(D.P1)`
  font-size: 1.6em;
  line-height: 1.6;
  margin-bottom: 10px;
  max-width:800px;
  margin-left: auto;
  margin-right: auto;
`;

const PInnerStyled = styled(D.P1)`
  font-size: 1.6em;
  line-height: 1.6;
  font-weight: 400;
  margin-bottom: 10px;
`;
const ButtonStyled = styled(Button)`
  margin-bottom: 20px;
  text-align:right;
`;
const StyledTableContainer = styled(TableContainer)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

const TextInputStyled = styled(TextInput)`
  margin-bottom: 20px;
`;

const FormWrapper = styled.div`
padding-left: 6em;
padding-right: 6em;
@media only screen and (max-width: 768px) {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 2em;
}
`
const CommentWrapper = styled.div`
padding-left: 6em;
padding-right: 6em;
@media only screen and (max-width: 768px) {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 2em;
}
`
const BorderWrapper = styled.div`
border: 2px solid ${D.darkGreen};
padding-top: 20px;
padding-bottom: 20px;
padding-left: 6em;
padding-right: 6em;
margin-bottom: 20px;
text-align: center;
display:flex;
justify-content:center;
flex-direction: column;
@media only screen and (max-width: 768px) {
  padding-left: 3em;
  padding-right: 3em;
}
`
const ImageContainer = styled.div`

`;

const StyledImage = styled.img`
  margin-left:auto;
  margin-right:auto;
  max-height:200px;
  margin-left: 5px;
  margin-right: 5px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 1em;
  }
`;

const Input = styled.textarea`
  background-color: #fefce8;
  border: none;
  text-align: top;
  height: 120px;
  font-size: 14px;
  font-family: 'BrownStd';
  color: '#003A1B';
  width: 80% !important;
  border-radius: 0;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 768px) {
    width: 80%
  }
`;

const H3Styled = styled(D.H3)`
  font-weight: 600;
  font-size: 2.4em;
  line-height: 1.4;
  margin-bottom: 0px;
  text-align: left;
  margin-left: 60px;
  margin-right:60px;
  @media only screen and (max-width: 1200px) {
    font-size: 1.8em;
    line-height: 1.8;
    text-align: center;
    margin-left: 20px;
    margin-right:20px;
  }
`;

// paleGreen,
//   paleBlue,
//   cream,
//   darkGreen,
//   palePink,
//   creamPink,
//   thirdPink,
//   gold,
//   paleGold,
//   midGreen,
//   creamGold,